// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prd` replaces `environment.ts` with `environment.prd.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    adminUrl: 'https://astralonecoreadminqa.channelsmart.net/',
    baseUrl: 'https://astralonecoreapiqa.channelsmart.net/',
    platformUrl: 'https://astralonecoreqa.channelsmart.net/',
    version: 'v1.0/',
    environmentKey: "8110B819-6F24-4570-9451-0E21AA03543D",
    nackleEnvironment: "qa",
    username: 'platform',
    password: '**********',
    gaKey: 'G-YM8GQTK5VC'
  };

/*
* For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.