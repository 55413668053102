import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    baseUrl = environment.baseUrl;
    versionName = environment.version;

    constructor(private http: HttpClient) { }

    get(apiEndPoint: string, responseType?: any): Observable<object> {
        if (responseType)
            return this.http.get(this.baseUrl + this.versionName  + apiEndPoint, responseType);
        else
            return this.http.get(this.baseUrl + this.versionName + apiEndPoint);
    }

    post(apiEndPoint: string, payload): Observable<object> {
        return this.http.post(this.baseUrl + this.versionName + apiEndPoint, payload);
    }

    put(apiEndPoint: string, payload): Observable<object> {
        return this.http.put(this.baseUrl + this.versionName + apiEndPoint, payload);
    }

    delete(apiEndPoint: string): Observable<object> {
        return this.http.delete(this.baseUrl + this.versionName + apiEndPoint);
    }

    download(apiEndPoint: string): Observable<object> {
        return this.http.get(this.baseUrl + this.versionName + apiEndPoint, { responseType: 'blob' });
    }

    downloadPost(apiEndPoint: string, payload): Observable<object> {
        return this.http.post(this.baseUrl + this.versionName + apiEndPoint, payload, { responseType: 'blob' });
    }
}
