

<div class="gameTab">
    <div class="">


        <div class="module-liner">

            <input type="hidden" id="tokenId" name="tokenId" [value]="tokenId" />
            <input type="hidden" id="locale" name="locale" [value]="" />
            <input type="hidden" id="ssoEndpoint" name="ssoEndpoint" [value]="ssoUrl" />

            <div class="module-content">
                <h3 class="section-header">Arcade Dashboard</h3>
                <div id="arcade-widget-container"></div>
            </div>
        </div>


       <!--  <div class="game-image-section">
                <img src="assets/images/game1.jpg" alt="games"/>
        </div>
        <div class="game-content-section">
                <h1 class="sub-pageHeader">Games arcade </h1>
                <p> Welcome to games aracde to complete ontime and win gifts  Welcome to games aracde to complete ontime and win gifts Welcome to games aracde to complete ontime and win gifts Welcome to games aracde to complete ontime and win gifts Welcome to games aracde to complete ontime and win gifts</p>
                <button class="btn btn-primary custombtn"> Play Game Now </button>
         </div> -->

    </div>

</div>