import { Component } from '@angular/core';

import { SharedService } from '@core/shared/services/shared.service';
import { WindowReferenceService, ICustomWindow } from '@core/shared/services/window-reference.service';

@Component({
  selector: 'profile-status-tab6',
  templateUrl: './profile-status-tab6.component.html',
  styleUrls: ['../scss/profile_status_charts.scss']
})
export class ProfileStatusTab6Component {
  gameToken: any;
  tokenId: string;
  ssoUrl: string;

  private _window: ICustomWindow;

  constructor(private sharedService: SharedService, public windowRef: WindowReferenceService) {
    this._window = windowRef.nativeWindow;
  }

  ngOnInit(): void {

    this.sharedService.getGameToken().subscribe((response: any) => {
      this._window.renderArcadeWidget({
        // Element to render the element into
        container: document.getElementById('arcade-widget-container'),
        // IETF language tag of the user's language and country code 
        locale: "en-GB",
        // JWT token of current user session
        sessionId: response.tokenId,
        // sso endpoint for the arcade floor
        ssoEndpoint: response.ssoUrl,
        // ***OPTIONAL*** - Use IF and ONLY IF you're using Nackle Paper to style your page.
        // This flag disables the Widget's own Nackle Paper stylesheet, allowing it to use the styles of the host page.
        useHostPageNacklePaper: false,
      });
    });
  }

}